<template lang="pug">
  transition(name='fade')
    b-card.lm-dashboard-panel-accounts-confirmed.card-white.card-no-gutters.shadow(no-body)
      b-card-header.bg-transparent.border-0
        h5.m-0
          b-link.text-body(:to='link' :disabled='!link')
            i.fa-fw.fal.fa-user-check
            |  {{ $t('activerecord.models.account.other') }} {{ $t('shared.basic.confirmed') }}
      b-card-body.d-flex.justify-content-center.align-items-center.pt-0
        .chart-wrapper(:style='`width: ${width}; height: ${height};`')
          client-only
            v-chart.chart(:option='chart')
</template>

<script>
import { ChartsDoughnut } from '@common/charts/doughnut'
import approx from 'approximate-number'

export default {
  name: 'lm-dashboard-panel-accounts-confirmed',
  mixins: [ChartsDoughnut],
  watch: {
    'panel.data'() {
      this.chart.title.text = `${approx(this.panel.data.attributes.chart[0].value, {
        separator: this.$t('number.format.delimiter'),
        decimal: this.$t('number.format.separator'),
      })}\n${this.$t(`shared.${this.panel.data.attributes.chart[0].name}`)}`
      this.chart.series[0].data = Array.from(this.panel.data.attributes.chart)
      this.chart.series[0].data.push({
        name: undefined,
        value: Math.max(
          0,
          this.panel.data.attributes.total - this.panel.data.attributes.chart[0].value
        ),
      })
    },
  },
  data() {
    return {
      cacheType: 'manager/dashboard/getPanelAccountsConfirmed',
      cacheOptions: { timeout: this.$wc.conf.cache.charts },
      skipNotifications: true,
      chart: {
        textStyle: {
          fontFamily: 'Montserrat,Helvetica Neue,Calibri,Arial,sans-serif',
        },
        title: {
          text: `0\n${this.$t('shared.basic.confirmed')}`,
          top: '35%',
          left: 'center',
          textStyle: {
            fontSize: '1rem',
            fontWeight: 'normal',
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            let name = params.data.name
            let value = params.data.value
            if (!params.data.name) {
              name = 'basic.total'
              value = this.panel.data.attributes.total
            }
            const key = name.startsWith('basic.')
              ? `shared.${name}`
              : `${this.panel.data.attributes.collection}.${name}`
            return `<center><b>${approx(value, {
              separator: this.$t('number.format.delimiter'),
              decimal: this.$t('number.format.separator'),
            })}</b>\n${this.$t(key)}<br /># ${value.toLocaleString(
              this.$wc.shared.locale
            )}</center>`
          },
        },
        legend: {
          top: '90%',
          left: 'center',
          itemWidth: 10,
          itemHeight: 10,
          formatter: (name) => {
            if (!name) name = 'basic.total'
            return name.startsWith('basic.')
              ? this.$t(`shared.${name}`)
              : this.panel.data.attributes
              ? this.$t(`${this.panel.data.attributes.collection}.${name}`)
              : name
          },
        },
        series: [
          {
            name: this.$t('activerecord.models.account.other'),
            type: 'pie',
            radius: ['75%', '100%'],
            left: 'center',
            bottom: '10%',
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: '#fff',
              borderWidth: 2,
              color: (params) => {
                return this.$getDeep(
                  this.panel.data.attributes.colors,
                  params.data.name || 'basic.total'
                )
              },
            },
            label: {
              show: false,
              position: 'center',
              fontSize: '1rem',
              formatter: (params) => {
                let name = params.data.name
                let value = params.data.value
                if (!params.data.name) {
                  name = 'basic.total'
                  value = this.panel.data.attributes.total
                }
                const key = name.startsWith('basic.')
                  ? `shared.${name}`
                  : `${this.panel.data.attributes.collection}.${name}`
                return `${approx(value, {
                  separator: this.$t('number.format.delimiter'),
                  decimal: this.$t('number.format.separator'),
                })}\n${this.$t(key)}`
              },
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              scale: false,
            },
            data: [],
          },
        ],
      },
    }
  },
}
</script>
