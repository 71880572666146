<template lang="pug">
  main#main.manager-dashboard
    b-container(fluid)
      wc-breadcrumb(icon='fal fa-tachometer')
      section.l-section
        .l-section-h
          b-row(cols=1 cols-md=2 cols-lg=3 cols-xl=4)
            b-col.mb-5(v-if='isAM')
              panel-accounts(
                :link="{ name: 'manager-accounts' }")

            b-col.mb-5(v-if='isAM')
              panel-accounts-active(
                :link="{ name: 'manager-accounts' }")

            b-col.mb-5(v-if='isAM')
              panel-accounts-confirmed(
                :link="{ name: 'manager-accounts' }")

            b-col.mb-5(v-if='isAM')
              panel-accounts-locked(
                :link="{ name: 'manager-accounts' }")

            b-col.mb-5(v-if='isAM')
              panel-accounts-accepted(
                :link="{ name: 'manager-accounts' }")

</template>

<script>
import WcBreadcrumb from '@components/shared/WcBreadcrumb'
import PanelAccounts from '@components/layouts/manager/dashboard/PanelAccounts'
import PanelAccountsActive from '@components/layouts/manager/dashboard/PanelAccountsActive'
import PanelAccountsConfirmed from '@components/layouts/manager/dashboard/PanelAccountsConfirmed'
import PanelAccountsLocked from '@components/layouts/manager/dashboard/PanelAccountsLocked'
import PanelAccountsAccepted from '@components/layouts/manager/dashboard/PanelAccountsAccepted'

export default {
  name: 'manager-dashboard',
  components: {
    WcBreadcrumb,
    PanelAccounts,
    PanelAccountsActive,
    PanelAccountsConfirmed,
    PanelAccountsLocked,
    PanelAccountsAccepted,
  },
  computed: {
    isAM() {
      return this.$store.getters['auth/isAM']
    },
  },
}
</script>
